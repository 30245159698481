<template>
  <div>

    <p class="mb-4 title">Form POS</p>


    <v-card class="rounded-lg shadow"
            :loading="isLoading"
            :disabled="isLoading">

      <v-card-text class="py-5 px-5">

        <v-radio-group row v-model="allow_salepoint_input" class="mt-3">
          <v-radio label="Activé" :value="true"></v-radio>
          <v-radio label="Désactivé" :value="false"></v-radio>
        </v-radio-group>

        <v-btn block color="primary" large depressed class="rounded-lg" @click="saveSetting">
          Enregistrer
        </v-btn>
      </v-card-text>

    </v-card>

  </div>
</template>
<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      isLoading: true,
      allow_salepoint_input: true
    };
  },
  methods: {
    saveSetting() {
      this.isLoading = true
      HTTP.post("/v1/settings/update-allow_salepoint_input", {
        allow_salepoint_input: this.allow_salepoint_input
      }).then(() => {
        this.isLoading = false
        this.$successMessage = "Mise a jour avec succès";
      }).catch((e) => {
        this.isLoading = false
      });
    },
    fetchData() {
      this.isLoading = true
      HTTP.get("/v1/settings").then((res) => {
        this.isLoading = false
        this.allow_salepoint_input = res.data.data && res.data.data.allow_salepoint_input ? res.data.data.allow_salepoint_input :false
      }).catch((e) => {
        console.log(e);
        this.isLoading = false
      });
    },
  },
  mounted() {
    this.fetchData();
  },
}
</script>

<style scoped>
</style>