<template>
  <div>

    <p class="mb-4 title">Boosting</p>


    <v-card class="rounded-lg shadow"
            :loading="isLoading"
            :disabled="isLoading">

      <v-card-text class="py-5 px-5">

        <v-radio-group row v-model="boosting_system" class="mt-3">
          <v-radio label="Activé" :value="true"></v-radio>
          <v-radio label="Désactivé" :value="false"></v-radio>
        </v-radio-group>

        <v-btn block color="primary" large depressed class="rounded-lg" @click="saveSetting">
          Enregistrer
        </v-btn>
      </v-card-text>

    </v-card>

  </div>
</template>
<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      isLoading: true,
      boosting_system: true
    };
  },
  methods: {
    saveSetting() {
      this.isLoading = true
      HTTP.post("/v1/settings/update-boosting_system", {
        boosting_system: this.boosting_system
      }).then(() => {
        this.isLoading = false
        this.$successMessage = "Mise a jour avec succès";
      }).catch((e) => {
        this.isLoading = false
      });
    },
    fetchData() {
      this.isLoading = true
      HTTP.get("/v1/settings").then((res) => {
        this.isLoading = false
        this.boosting_system = res.data.data && res.data.data.boosting_system ? res.data.data.boosting_system :false
      }).catch((e) => {
        console.log(e);
        this.isLoading = false
      });
    },
  },
  mounted() {
    this.fetchData();
  },
}
</script>

<style scoped>
</style>