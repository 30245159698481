<template>
  <div>

    <p class="mb-4 title">OPUS</p>


    <v-card class="rounded-lg shadow"
            :loading="isLoading"
            :disabled="isLoading">

      <v-card-text class="py-5 px-5">

        <v-radio-group row v-model="opus_system" class="mt-3">
          <v-radio label="Activé" :value="true"></v-radio>
          <v-radio label="Désactivé" :value="false"></v-radio>
        </v-radio-group>

        <v-text-field prepend-icon="mdi-link"
                      label="Lien du tutoriel"
                      v-model="tutorial_link"
                      outlined
                      dense
        ></v-text-field>

        <v-file-input @change="uploadImage($event)"
                      label="Image du tutoriel"
                      :clearable="false"
                      outlined
                      dense
                      accept="image/*"
        ></v-file-input>

        <div v-if="tutorial_image">
          <div class="d-flex justify-space-between pa-3 rounded grey lighten-4 mb-5">
            <v-avatar size="40" rounded>
              <v-img :src="FILE_URL + tutorial_image" class="rounded cursor-pointer"/>
            </v-avatar>
            <v-spacer/>
            <v-btn color="red" icon @click="tutorial_image = null">
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </div>
        </div>

        <v-btn block color="primary" large depressed class="rounded-lg" @click="saveSetting">
          Enregistrer
        </v-btn>
      </v-card-text>

    </v-card>

  </div>
</template>
<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      FILE_URL: process.env.VUE_APP_FILE_URL,
      isLoading: true,
      opus_system: true,
      tutorial_image: null,
      tutorial_link: null,
    };
  },
  methods: {
    uploadImage(file) {
      this.isLoading = true
      const formData = new FormData();
      formData.append("image", file);
      HTTP.post("/v1/opus/campaigns/upload/image", formData).then((res) => {
        this.isLoading = false
        this.tutorial_image = res.data.data
      }).catch((e) => {
        this.isLoading = false
      });
    },
    saveSetting() {
      this.isLoading = true
      HTTP.post("/v1/settings/update-opus_system", {
        opus_system: this.opus_system,
        tutorial_image: this.tutorial_image,
        tutorial_link: this.tutorial_link,
      }).then(() => {
        this.isLoading = false
        this.$successMessage = "Mise a jour avec succès";
      }).catch((e) => {
        this.isLoading = false
      });
    },
    fetchData() {
      this.isLoading = true
      HTTP.get("/v1/settings").then((res) => {
        this.isLoading = false
        this.opus_system = res.data.data && res.data.data.opus_system ? res.data.data.opus_system : false
        this.tutorial_image = res.data.data && res.data.data.tutorial_image ? res.data.data.tutorial_image : null
        this.tutorial_link = res.data.data && res.data.data.tutorial_link ? res.data.data.tutorial_link : null
      }).catch((e) => {
        console.log(e);
        this.isLoading = false
      });
    },
  },
  mounted() {
    this.fetchData();
  },
}
</script>

<style scoped>
</style>